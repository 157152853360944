import React from "react"
import mechanician from "../assets/images/mechanician.jpeg"

export default function WhyUs() {
  return (
    <section className="px-7 py-32 lg:flex lg:px-32 lg:justify-between">
      <img
        src={mechanician}
        alt="mechanician"
        className="w-full pb-16 lg:w-2/5 lg:mr-16 lg:mt-12"
      />
      <div className="lg:w-3/5">
        <p className="text-custom-red  mb-5">
          Here are just some reasons why you should try us
        </p>
        <h3 className="text-custom-red text-5xl font-bold pb-8">WHY US</h3>
        <div className="flex items-center mb-4">
          <p className="text-8xl font-extralight text-custom-red pr-4 w-36">
            01.
          </p>
          <div className="w-8/12">
            <h3 className="text-xl font-bold pb-2 uppercase">
              Quality Assurance
            </h3>
            <p className="text-sm">
              Not every shop dedicates a specialist for each car, but we
              certainly spare no expence to ensure the quality of our work.
            </p>
          </div>
        </div>
        <div className="flex items-center  mb-4">
          <p className="text-8xl font-extralight text-custom-red pr-4 w-36">
            02.
          </p>
          <div className="w-8/12">
            <h3 className="text-xl uppercase font-bold pb-2">
              problem solving
            </h3>
            <p className="text-sm">
              Decades of experience have given our staff the edge in fixing some
              of the most mind-wracking colliosions.
            </p>
          </div>
        </div>
        <div className="flex items-center  mb-4">
          <p className="text-8xl font-extralight text-custom-red pr-4 w-36">
            03.
          </p>
          <div className="w-8/12">
            <h3 className="text-xl uppercase font-bold pb-2">Tried and true</h3>
            <p className="text-sm">
              If you don't believe us, believe our clients! Our bulletproof
              ratings say everything that needs to be said!
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

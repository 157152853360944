import React from "react"
import aboutImg from "../assets/images/AboutImg.png"

export default function AboutUs() {
  return (
    <div
      className="pt-32 pb-9 px-7 flex flex-col lg:px-32 xl:flex-row-reverse lg:justify-center"
      id="about"
    >
      <div className="lg:ml-24 lg:max-w-3xl">
        <p className="text-custom-red mb-5">
          What makes us stand out from the competition?
        </p>
        <h3 className="text-custom-red text-5xl font-bold mb-9 uppercase">
          about us
        </h3>
        <p className="mb-9">
          Our credibility stems from our experienced staff, excellent track
          record, and of course, our client testimonials. Having a team of
          knowledgable veterans, coupled with a user-centric approach to our
          clients has been the backbone of our success for the last 10 years.
        </p>
        <p className="mb-9">And it isn't about to change now.</p>
        <p className="mb-9">
          Our mission is to provide our clients with quality services that help
          keep their vehicles operationally healthy for years to come.
        </p>
      </div>
      <img
        className="md:max-w-md self-center mt-9"
        src={aboutImg}
        alt="about-us"
      />
    </div>
  )
}

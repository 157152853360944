import React from "react"
import logo from "../assets/images/logofooter.png"

export default function Footer() {
  return (
    <footer className="bg-black p-8 text-gray-400 md:px-16 xl:px-48">
      <div className="md:flex md:justify-between">
        <div className="flex flex-col items-center py-8 md:w-2/5 xl:w-1/4">
          <img src={logo} alt="logo" className="p-8 md:px-0" />
          <p className="text-center w-4/5 text-sm md:text-left md:w-full">
            Our mission is to provide our clients with quality services that
            help keep their vehicles operationally healthy for years to come.
          </p>
        </div>
        <div className="md:w-2/5 xl:w-1/3">
          <div className="flex justify-between py-16">
            <div className="flex flex-col">
              <h2 className="pb-5">NAVIGATION</h2>
              <a href="/#home" className="text-sm pb-2 hover:opacity-60">
                Home
              </a>
              <a href="/#about" className="text-sm pb-2 hover:opacity-60">
                About Us
              </a>
              <a href="/#services" className="text-sm pb-2 hover:opacity-60">
                Services
              </a>
              {/* <a href="#" className="text-sm pb-2 hover:opacity-60">FAQs</a> */}
            </div>
            <div className="flex flex-col">
              <h2 className="pb-5">Contact Us</h2>
              <a
                href="tel:+1-604-231-0061"
                className="text-sm pb-2 hover:opacity-60"
              >
                (604) 231-0061
              </a>
              <a
                href="mailto:info@colourperfection.net"
                className="text-sm pb-2 hover:opacity-60"
              >
                info@colourperfection.net
              </a>
              <p className="text-sm">12211 Vulcan Way #150, </p>
              <p className="text-sm pb-2">Richmond, BC V6V 1J7</p>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-dashed px-4 md:px-12 pb-1 flex justify-between">
        <div>
          <p className="text-xs">&#169; 2021 All Rights Reserved</p>
        </div>
        <div className="flex">
          <p className="text-xs pr-4">Terms and Conditions</p>
          <p className="text-xs">Privacy Policy</p>
        </div>
      </div>
    </footer>
  )
}

import Header from "./components/Header"
import Navbar from "./components/Navbar"
import AboutUs from "./components/AboutUs"
import ExperiencedStaff from "./components/ExperiencedStaff"
import Services from "./components/Services"
import Footer from "./components/Footer"
import WhyUs from "./components/WhyUs"

function App() {
  return (
    <div className="font-body">
      <Navbar />
      <Header />
      <AboutUs />
      <ExperiencedStaff />
      <Services />
      <WhyUs />
      <Footer />
    </div>
  )
}

export default App

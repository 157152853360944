import React from "react"
import staff from "../assets/images/staff1.png"

export default function ExperiencedStaff() {
  return (
    <div className="pt-32 pb-9 px-7 flex flex-col lg:px-32 xl:flex-row lg:justify-center">
      <div className="lg:mr-24 lg:max-w-xl">
        <p className="text-custom-red mb-5">
          Our tried-and-true staff are experts in their fields
        </p>
        <h3 className="text-custom-red text-5xl font-bold mb-9 uppercase">
          experienced staff
        </h3>
        <p className="mb-9">
          With decades of experience under their belt, the staff working at
          Colour Perfection consist of licensed automotive mechanics and
          technicians that specialize in a range of different applicators.
        </p>
        <p className="mb-9">
          An expert overlooks each car that comes into our shop, and will be
          responsible from the moment it enters our lot, until it leaves.
          Ensuring that all our clients benefit from the undivided attention of
          our specialists.
        </p>
      </div>
      <div className="self-center">
        <img className="" src={staff} alt="staff" />
      </div>
    </div>
  )
}

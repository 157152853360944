import React from "react"
import servicebg from "../assets/images/servicebg.png"
import collisionRepair from "../assets/images/collision-repair.png"
import pdr from "../assets/images/pdr.png"

export default function Services() {
  return (
    <div
      className="bg-cover bg-no-repeat min-w-full py-24 px-7 flex flex-col items-center"
      id="services"
      style={{ backgroundImage: `url('${servicebg}')` }}
    >
      <h3 className="text-white text-5xl font-bold mb-9 uppercase">
        our services
      </h3>
      <p className="text-white text-center">
        Our experts specialize specifically in Complete Collision Repair and
        Paintless Dent Repairs.
      </p>
      <div className="mt-20 flex">
        <div className="flex flex-col items-center mr-3">
          <img src={collisionRepair} alt="collision repair" />
          <div className="bg-white w-full flex justify-center py-4">
            <h4 className="text-custom-red text-center text-lg capitalize font-bold">
              complete collision repairs
            </h4>
          </div>
        </div>
        <div className="flex flex-col items-center ml-3">
          <img src={pdr} alt="collision repair" />
          <div className="bg-white w-full flex justify-center py-4">
            <h4 className="text-custom-red text-center text-lg capitalize font-bold">
              paintless dent repairs
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import heroImage from "../assets/images/Header.png"

export default function Header() {
  return (
    <div
      className="h-mobile-header bg-cover bg-no-repeat min-w-full lg:h-screen"
      id="home"
      style={{ backgroundImage: `url('${heroImage}')` }}
    >
      <div className="h-72 bg-custom-red bg-opacity-60 flex flex-col justify-end pl-5 pb-5 md:h-full md:w-3/4 md:px-16 lg:w-1/2 lg:justify-center">
        <h1 className="uppercase text-5xl text-white font-bold mb-5">
          colour perfection
        </h1>
        <h2 className="text-3xl text-white italic">Collision Repair</h2>
        <div className="py-7 hidden md:flex flex-col">
          <p className="text-white text-base mb-9">
            "There's no such thing as perfection. When you focus on one thing,
            you tend to neglect another"
          </p>
          <p className="text-white text-base mb-20">
            That's why at Colour Perfection, we have a dedicated group of
            experts that specialize in each of the services we offer. So that we
            can stay focused on what matters most.
          </p>
          <a className="justify-self-end" href="/#services">
            <button className="capitalize text-custom-red bg-white font-bold text-2xl px-8 py-3 rounded shadow">
              our services
            </button>
          </a>
        </div>
      </div>
      <div className="py-10 px-4 md:hidden">
        <p className="text-white text-base mb-9">
          "There's no such thing as perfection. When you focus on one thing, you
          tend to neglect another"
        </p>
        <p className="text-white text-base">
          That's why at Colour Perfection, we have a dedicated group of experts
          that specialize in each of the services we offer. So that we can stay
          focused on what matters most.
        </p>
      </div>
      <div className="flex flex-col items-center md:hidden">
        <button className="capitalize text-custom-red bg-white font-bold text-2xl px-8 py-3 rounded shadow">
          our services
        </button>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import menuIcon from "../assets/icons/icons8-menu.svg"
import logo from "../assets/images/logonav.png"

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false)

  const handleClick = () => {
    const size = window.matchMedia("(max-width: 767px)")

    if (size.matches) setOpenMenu((prevState) => !prevState)
  }

  return (
    <nav className="bg-white bg-opacity-75 pr-8 py-1 md:flex md:px-10 md:justify-between">
      <div className="flex justify-between w-full md:w-96 ">
        <img className="" src={logo} alt="logo" />
        <img
          className="h-10 mt-2 text-red-600 md:hidden"
          src={menuIcon}
          alt="menu"
          onClick={handleClick}
        />
      </div>
      <ul
        className={
          openMenu === false
            ? "hidden md:flex md:mt-5"
            : "flex flex-col items-center"
        }
      >
        <li className="md:mx-5 font-medium">
          <a onClick={handleClick} href="/#home">
            Home
          </a>
        </li>
        <li className="md:mx-5 font-medium">
          <a onClick={handleClick} href="/#about">
            About
          </a>
        </li>
        <li className="md:mx-5 font-medium">
          <a onClick={handleClick} href="/#services">
            Services
          </a>
        </li>
      </ul>
    </nav>
  )
}
